<template>
  <div>
    <div
      v-if="isLoading"
      class="pa-4"
    >
      <v-progress-linear
        color="primary"
        indeterminate
      />
      <h6 class="text-center text-subtitle-2 mt-3">Carregando...</h6>
    </div>
    <FormCard
      v-else
      :title="
        client.name
          ? client.name
          : client.company_name
          ? client.company_name
          : 'Novo Cliente'
      "
      :createdAt="client.created_at"
      :status="client.status"
      @save="validate"
      @cancel="$router.push({ name: 'clients' })"
    >
      <v-form
        class="px-5 mt-5"
        ref="form"
      >
        <input
          type="password"
          name="password"
          label="senha"
          class="d-none"
          autocomplete="new-password"
        />
        <v-tabs v-model="currentTab">
          <v-tabs-slider color="primary" />

          <v-tab
            v-for="item in tabsItems"
            :key="item.id"
            :disabled="isDisabled(item.title)"
          >
            {{ item.title }}
          </v-tab>

          <v-tabs-items v-model="currentTab">
            <v-tab-item
              v-for="item in tabsItems"
              :key="item.id"
              class="py-10"
            >
              <component
                :client="client"
                :password="password"
                :is="item.component"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-form>
    </FormCard>
  </div>
</template>

<script>
export default {
  components: {
    FormCard: () => import('@/components/FormCard'),
    MainData: () => import('./form-sections/MainData'),
    Crms: () => import('./form-sections/Crms'),
    Budgets: () => import('./form-sections/Budgets'),
    Orders: () => import('./form-sections/Orders'),
    MainDataClient: () => import('./form-sections-client/MainData'),
    BudgetsClient: () => import('./form-sections-client/Budgets'),
    OrdersClient: () => import('./form-sections-client/Orders'),
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
    password: String,
    type: String,
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    currentTab: null,
    tabsItems: [
      { id: 1, title: 'Dados Principais', component: 'main-data' },
      { id: 2, title: 'CRM', component: 'crms' },
      { id: 3, title: 'Orçamentos', component: 'budgets' },
      { id: 4, title: 'Pedidos', component: 'orders' },
    ],
    tabsItemsClient: [
      { id: 1, title: 'Dados Principais', component: 'main-data-client' },
      { id: 2, title: 'Orçamentos', component: 'budgets-client' },
      { id: 3, title: 'Pedidos', component: 'orders-client' },
    ],
    profile: null,
  }),
  methods: {
    redirectTo() {
      window.location.href = this.$store.state.hostFront
    },
    isDisabled(title) {
      return this.$route.matched[1].name === 'clients-new' && title === 'CRM'
    },
    validate() {
      console.log(this.client)
      if (this.client.seller_id === null) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao atualizar este cliente',
          caption: 'Campo Vendedor é obrigatório',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      } 
      else if (this.client.business_id === null) {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao atualizar este cliente',
          caption: 'Campo Ramo de Atuação é obrigatório',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      } else {
        let isValid = this.$refs.form.validate()
        isValid ? this.$emit('save') : ''
      }
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }
  },
}
</script>
